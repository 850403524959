import * as React from 'react'
import * as classes from './zcatalog.module.scss'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import { Range } from 'rc-slider'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function CatalogModule({ withSearch }) {
  const primary = '#ffc700'
  const data = useStaticQuery(graphql`
    query Catalog {
      allCatalogJson {
        nodes {
          title
          type
          link
          propertys {
            title
            value
          }
          price
          oldPrice
          addText {
            title
            text
          }
          images {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  quality: 99
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)
  if (typeof window !== `undefined` && typeof document !== `undefined`) {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset >= 200) {
        document.getElementById('btnSearch')?.classList.add(`d-block`)
      } else {
        document.getElementById('btnSearch')?.classList.remove(`d-block`)
      }
    })
  }
  //экраны меньше 768
  const small =
    typeof window !== `undefined` ? window.matchMedia('(max-width:959px)').matches : null
  // один итем - одна карточка
  const items = data.allCatalogJson.nodes

  // дополнительная информация во всплывашке, клик по документу вне - закрывает всплывашку
  const [text, setText] = React.useState(-1)

  // стейт для галлереи
  const [litebox, setLitebox] = React.useState({
    photoIndex: 0,
    slideNumber: 0,
    isOpen: false
  })

  // создаем массив со ВСЕМИ фотками
  const img = []
  items.map((item) => {
    item.images.map((image) => {
      img.push(image.image.childImageSharp.gatsbyImageData.images.fallback.src)
      return img
    })
    return img
  })

  // берем только те фотки которые находятся внутри карточки
  let count = 0
  if (litebox.slideNumber > 0) {
    let i = 0
    do {
      count += items[i].images.length
      i = i + 1
    } while (i < litebox.slideNumber)
  } else count = 0
  const images = img.slice(count, count + items[litebox.slideNumber].images.length)

  //Создаем вкладки категорий находя уникальные с json и по дефолту показываем все
  const [tab, setTab] = React.useState('')
  const allTypes = []
  const types = []
  items.map((item) => {
    allTypes.push(item.type)
    for (let unique of allTypes) {
      if (!types.includes(unique)) {
        types.push(unique)
      }
    }
    return types
  })

  //создаем массив цен находим макс и мин в зависимости от фильтра
  const prices = []
  if (tab !== '') {
    items
      .filter((item) => item.type === tab)
      .map((item) => {
        prices.push(item.price)
        return prices
      })
  } else {
    items.map((item) => {
      prices.push(item.price)
      return prices
    })
  }
  const minPrice = Math.min.apply(null, prices)
  const maxPrice = Math.max.apply(null, prices)
  const [value, setValue] = React.useState([minPrice, maxPrice])
  const onSliderChange = (value) => {
    setValue(value)
  }

  // обновляем стейт с валю для корректной работы слайдера
  React.useEffect(() => {
    setValue([minPrice, maxPrice])
  }, [minPrice, maxPrice])

  // менюшка поиска всегда слева

  const [btnSearch, setBtnSearch] = React.useState(false)

  return (
    <div className={classes.catalogModule}>
      {/* Блок галереи */}
      {litebox.isOpen && (
        <Lightbox
          mainSrc={images[litebox.photoIndex]}
          onCloseRequest={() => setLitebox({ ...litebox, isOpen: false })}
          nextSrc={images[(litebox.photoIndex + 1) % images.length]}
          prevSrc={images[(litebox.photoIndex + images.length - 1) % images.length]}
          onMoveNextRequest={() =>
            setLitebox({
              ...litebox,
              photoIndex: (litebox.photoIndex + 1) % images.length
            })
          }
          onMovePrevRequest={() =>
            setLitebox({
              ...litebox,
              photoIndex: (litebox.photoIndex + images.length - 1) % images.length
            })
          }
        />
      )}
      {/* Для странички каталога добавляем поиск */}
      {withSearch ? (
        <>
          <div>
            <div className={classes.btnSearch} id="btnSearch">
              <button onClick={() => setBtnSearch(!btnSearch)}>
                {btnSearch ? `Свернуть поиск` : `Развернуть поиск`}
              </button>
            </div>
            <div className={btnSearch ? `${classes.search} ${classes.show}` : `${classes.search}`}>
              <p>Поиск</p>
              {types.map((type, index) => {
                return (
                  <button
                    className={type === tab ? classes.active : ''}
                    onClick={tab !== type ? () => setTab(type) : () => setTab('')}
                    key={index}
                  >
                    <span>{type}</span>
                  </button>
                )
              })}
              <div>
                <p>Цена от:</p>
                <Range
                  count={1}
                  value={value}
                  step={5000}
                  min={minPrice}
                  max={maxPrice}
                  trackStyle={[{ backgroundColor: primary }]}
                  handleStyle={[
                    { backgroundColor: primary, border: 0, boxShadow: 'none' },
                    { backgroundColor: primary, border: 0, boxShadow: 'none' }
                  ]}
                  onChange={onSliderChange}
                />
                <div className={`d-flex justify-content-between`}>
                  <div>{value[0]}</div>
                  <div>{value[1]}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* Если с поиском добавляем класс с меньшей шириной */}
      <Row className={classes.cards}>
        {/* Берем все товары и фильтруем по ценам */}
        {items
          .filter((item) => item.price >= value[0] && item.price <= value[1])
          .map((item, index) => {
            return (
              // Отображаем только товары в соответствующей вкладке
              (item.type === tab || tab === '') && (
                // кол- карточка с фоном тенью и бордеррадиусом в которой 2 блока с инфой и допИнфой
                <Col md={6} key={index}>
                  <div
                    className={text === index ? `${classes.card} ${classes.show}` : classes.card}
                  >
                    <Row className={classes.inner}>
                      <Col xl={6} lg={5}>
                        <p className={classes.title}>{item.title}</p>
                        <Row className={classes.imagesRow}>
                          <Col
                            xs={12}
                            onClick={() =>
                              setLitebox({
                                ...litebox,
                                isOpen: true,
                                photoIndex: 0,
                                slideNumber: index
                              })
                            }
                          >
                            <GatsbyImage image={getImage(item.images[0].image)} alt={`image`} />
                          </Col>
                          <Col
                            md={6}
                            lg={12}
                            xl={6}
                            className={`d-none d-md-block`}
                            style={{ marginTop: '15px' }}
                            onClick={() =>
                              setLitebox({
                                ...litebox,
                                isOpen: true,
                                photoIndex: 1,
                                slideNumber: index
                              })
                            }
                          >
                            <GatsbyImage image={getImage(item.images[1].image)} alt={`image2`} />
                          </Col>
                          <Col
                            md={6}
                            lg={12}
                            xl={6}
                            className={`d-none d-md-block `}
                            style={{ marginTop: '15px' }}
                            onClick={() =>
                              setLitebox({
                                ...litebox,
                                isOpen: true,
                                photoIndex: 2,
                                slideNumber: index
                              })
                            }
                          >
                            <GatsbyImage image={getImage(item.images[2].image)} alt={`image3`} />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} lg={7}>
                        <div className={classes.itemInfo}>
                          <p className={classes.desc}>Характеристики:</p>
                          <ul>
                            {item.propertys.map((prop, i) => {
                              return (
                                <li key={i} style={withSearch ? { fontSize: '14px' } : null}>
                                  <div>{prop.title}:</div>
                                  <div>{prop.value}</div>
                                </li>
                              )
                            })}
                          </ul>
                          <p className={classes.price}>
                            Цена: от{' '}
                            {/* {item.oldPrice ? (
                              <span className="text-through-light">
                                {item.oldPrice.toLocaleString('ru') + '₽'}{' '}
                              </span>
                            ) : (
                              ''
                            )} */}
                            {item.price.toLocaleString('ru') + '₽'}
                          </p>
                          <div className={classes.button}>
                            <Link to={item.link}>Подробнее</Link>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <hr className="m-0" />
                    <div
                      className={
                        text === index ? `${classes.addInfo} ${classes.show}` : classes.addInfo
                      }
                    >
                      <button onClick={text === index ? () => setText(-1) : () => setText(index)}>
                        {text === index ? `Свернуть` : `Базовая комплектация`}
                      </button>
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={small ? () => setText(-1) : null}
                        onKeyDown={small ? () => setText(-1) : null}
                      >
                        {item.addText.map((addText, i) => {
                          return (
                            <div key={i}>
                              <p>{addText.title}</p>
                              <p>{addText.text}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            )
          })
          // если не страница каталога то показываем 4 на десктопе или 2 на мобилке
          .slice(0, withSearch ? items.length : small ? 2 : 4)}
      </Row>
    </div>
  )
}
